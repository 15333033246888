$(document).ready(function($){
  // Get page href and set opened tab, default to first if one isn't available
  // Only run this if tabs actually exist
  if ($('.tab-container').length > 0) {
    var tabContentSectionID = $(location).attr('hash');

    // If the id doesn't exist on the page, update tabContentSelectionID to grab the first href of the tab buttons
    if ($(tabContentSectionID).length < 1) {
      tabContentSectionID = $(".tab-container__link-list li:first-child a").attr("href")
    }

    // Show content block that matches passed ID, update tab link with matching href
    $(tabContentSectionID).show();
    $("a[href='" + tabContentSectionID + "']").addClass('active');
  }

  // Add click event to any anchor tag in a tab container.
  // This includes the top tabs, as well as any inline links.
  // Then, exclude any accordions as they have a href of '#'
  $(".tab-container a[href^='#']").not('.expanding_title').not('.video-player a').click(function (event) {
    showSelectedTab($(this).attr("href"));
  });
});

function showSelectedTab(clickedTabID) {
  // Reset tabs ahead of changing active tab
  $(".tab-container__link-list li a").removeClass('active');
  $(".tab-container__content").hide();

  // Check tab had an href, as some link directly to external pages
  if (clickedTabID.indexOf('#') != -1) {
    // Show content block that matches new ID, update tab link with matching href
    $(clickedTabID).show();
    $("a[href='" + clickedTabID + "']").addClass('active');
  }
}
