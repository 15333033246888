import "./jquery";
import "jquery-ujs";
import "./lib/classie";
import "./lib/eventListenerPolyfill.min";
import "./lib/jquery.validate.min";
import "./lib/jquery.jstepper.min";
import "./lib/tabs";
import "./google_analytics";
import "./interactive_form";
import "./internal_tabs";
import "./page_feedback";
import "./revamp";
import "./video_player";
import "./welcome.coffee";
