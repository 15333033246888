$(document).ready(function($){

  $('.tracked-link, .investment-tracked-link').click( function() {
    gtag("event", "Tracked tab clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  });

  $('.link-tracked').click( function() {
    gtag("event", "Tracked link clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  $('.tracked-accordion').click( function() {
    gtag("event", "Accordion clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  $('.internal-page-tracked-link').click( function() {
    gtag("event", "Internal page tracked link clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  $('.duplicate-internal-page-tracked-link').click( function() {
    gtag("event", "Internal page tracked link clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href'),
      link_position: $(this).data('position')
    });
  })

  $('.top-link-extra a').click( function() {
    gtag("event", "Top link item clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  //   # Homepage tracking

  $('.lbg-button').click( function() {
    gtag("event", "Main button clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  $('.tracked-tile').click( function() {
    gtag("event", "Homepage secondary tile clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  $('.tracked-primary-tile').click( function() {
    gtag("event", "Homepage primary tile clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  $('.sliding-tabs__content a').click( function() {
    gtag("event", "Quick link visited", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  $('.footer__links a').click( function() {
    gtag("event", "Footer item clicked", {
      link_location: window.location.href,
      link_url: $(this).attr('href')
    });
  })

  // HTT

  $(".lt-welcome-page_start-btn").click( function() {
    gtag("event", "Hitting the target started")
  })

  $(".ahc-lifetime-footer_links button").click( function() {
    gtag("event", "Hitting the target disclaimer")
  })

});