$(document).ready(function($){
  jQuery('#sldrFlexibleRetirement').advSlider({
    orientation : "vertical",
    min : "0",
    max : "2",
    step : "0.01",
    value : "1",
    onChange : function(event, ui) {
      var updateCallout = function() {
        //hide all flexible retirement popups
        jQuery(".flexible-retirement-popup").hide();

        //get popup to show, make visible
        var selection = jQuery("#flexible-retirement-" + Math.round(ui.value));
        selection.toggle();

        //reposition popup to follow slider thumb
        var handlePosition = jQuery(ui.handle).position();
        selection.css('margin-top', handlePosition.top + 10);
      }
      // wait for the ui.handle to set its position, otherwise the reported handle position lags behind in some browsers
      setTimeout(updateCallout, 5);
    }
  });
  // jQuery('#sldrFlexibleRetirement').slider("option", "value", 1);

  $('.no_js').hide();
  $('#other_title_container').hide();
  $('.interactive_form').show();

  $('#title').change(function()
  {
    $(this).val() == 'Other' ? $('#other_title_container').fadeIn() : $('#other_title_container').fadeOut();
  });

  $('.print.button').click(function(event){
    event.preventDefault();
    if($('#interactive_form').valid())
    {
      window.print();
    }
    else
    {
      $('.print_error').remove();
      $('.print.button').parent().before('<label class="print_error">Please correct the errors shown above before printing.</label>');
    }
  });

  /**
   * Initialises steppers with plus/minus buttons.
   * Adds 100% allocation check.
   * Assumes the stepper is rate based (0-100%).
   */
  $('.stepper').each(function()
  {
    var minvalue = 0;
    var maxValue = 100;

    var minusButton = $('<a href="#" class="minus button">&minus;</a>').click(function(event)
    {
      event.preventDefault();
      var input = $(this).next('input');
      if(input.val() > minvalue)
      {
        input.val(parseInt(input.val()) - 1);
      }
      updateAllocation();
    });
    var plusButton = $('<a href="#" class="plus button">+</a>').click(function(event)
    {
      event.preventDefault();
      var input = $(this).prev('input');
      if(input.val() < maxValue)
      {
        input.val(parseInt(input.val()) + 1);
      }
      updateAllocation();
    });

    $(this).val(0).jStepper({minValue:0, maxValue:100, minLength:1})
    .keyup(function()
    {
      updateAllocation();
    })
    .before(minusButton)
    .after(plusButton);
  });

  $('#interactive_form').validate({
    ignore : ':hidden',
    onkeyup : false,
    errorClass : 'field_error',
    rules :
    {
      title :
      {
        required : true,
        accept : mixedCaseAlpha()
      },
      other_title :
      {
        required : true
      },
      firstname :
      {
        required : true,
        accept : mixedCaseAlpha()
      },
      surname :
      {
        required : true,
        accept : mixedCaseAlpha()
      },
      nino :
      {
        required : true,
        regex : "^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$"
      },
      phone :
      {
        required : true,
        number : true
      },
      email :
      {
        required : false,
        email : true
      }
    },
    groups :
    {
      nomination_proportion: "nomination_1_proportion nomination_2_proportion nomination_3_proportion nomination_4_proportion"
    },
    errorPlacement : function(error, element)
    {
      if(element.attr("name") == 'nomination_1_proportion' || element.attr("name") == 'nomination_2_proportion' || element.attr("name") == 'nomination_3_proportion' || element.attr("name") == 'nomination_4_proportion')
      {
        error.insertBefore('#nomination_allocation');
      }
      else
      {
        error.insertAfter(element);
      }
    },
    messages :
    {
      surname :
      {
        accept : "Your surname cannot contain special characters or numbers"
      },
      firstname :
      {
        accept : "Your first name(s) cannot contain special characters or numbers"
      },
      title :
      {
        accept : "Your title cannot contain special characters or numbers"
      },
      nino :
      {
        regex : "This field must be in the format AB 12 34 56 C"
      }
    },
    onfocusout : function(element)
    {
      if($(element).hasClass('stepper'))
      {
        if(!$(element).val())
        {
          $(element).val(0);
        }
      }
      else
      {
        $(element).valid();
      }
    },
    highlight: function (element, errorClass, validClass)
    {
      if($(element).hasClass('stepper') || $(element).hasClass('nomination_field'))
      {
        $(element).parents('tr').addClass(errorClass).removeClass(validClass);
      }
    },
    unhighlight: function (element, errorClass, validClass)
    {
      if($(element).hasClass('stepper') || $(element).hasClass('nomination_field'))
      {
        $(element).parents('tr').removeClass(errorClass).addClass(validClass);
      }
    }
  });

  /**
   * Trim white space from input ahead of validation. Simplifies the regex/test.
   */
  $('#nino, #phone').focusout(function()
  {
    this.value = this.value.replace(/\s+/g, '');
  });

  $.validator.addMethod('regex', function(value, element, regexp)
  {
    var re = new RegExp(regexp);
    return this.optional(element) || re.test(value);
  }, $.validator.messages.regex);

  $.validator.addMethod('accept', function(value, element, param)
  {
    return value.match(new RegExp('^' + param + '$'));
  }, $.validator.messages.accept);

  $.validator.addMethod('must_equal', function(value, element, param)
  {
    return totalAllocation() == param;
  }, $.validator.format('Your total allocation must equal {0}%'));

  $.validator.addMethod('greater_than', function(value, element, param)
  {
    return $(element).val() > param;
  }, $.validator.format('Allocation must be greater than {0}%'));

  $.validator.addClassRules("stepper",
  {
    greater_than: function(element){
      var greaterThanVal = -1;  //i.e. min allowed val is zero
      //establish if any corresponding text fields have values in them, and if so set min allowed stepper value to be greater than zero
      //this stops someone sending in a form with a name/address/relationship stated and a corresponding zero % allocation (as other nominations may have already reached 100% which the "must_equal" validation rule wouldn't pick up)
      $(element).closest('tr').find('.nomination_field').each(function()
      {
        if($(this).val() != "")
        {
          greaterThanVal = 0;
        }
      });
      return greaterThanVal;
    },
    must_equal: 100
  });

  /**
   * Makes corresponding text inputs required if stepper is greater than zero.
   */
  $.validator.addClassRules("nomination_field",
  {
    required :
    {
      depends : function(element)
      {
        if($(element).hasClass('relationship'))
        {
          return false;
        }
        var relatedStepper = $(element).closest('tr').find('.stepper');
        return parseFloat(relatedStepper.val()) > 0;
      }
    }
  });

  /**
   * RegEx to validate name based inputs
   * Allows lowercase, uppercase, dashes, covers unicode range to allow for characters such as eacute
   */
  function mixedCaseAlpha()
  {
    return "([ \u00c0-\u01ffa-zA-Z'\-])+"
  }

  /**
   * Ensures combined stepper values do not exceed 100%
   */
  function updateAllocation()
  {
    //removes error highlighting as soon as allocation is 100% as fields have had focusout/blur validation disabled for UX reasons
    if(totalAllocation() == 100)
    {
      $('.stepper').each(function()
      {
        $(this).valid();
      });
    }
    $('table .footer .selection').html(totalAllocation() + "%");
  }

  function totalAllocation()
  {
    var totalAllocation = 0;

    $('.stepper').each(function(){
      var val = $(this).val();
      totalAllocation += parseFloat(val ? val : 0);
    });

    return totalAllocation;
  }

  updateAllocation();

  var originalFormData = $("#interactive_form").serialize();

  /**
   * Triggers navigate away warning if form has changed
   */
  window.onbeforeunload = function(){
    if($("#interactive_form").serialize() != originalFormData) {
      return 'Navigating away from this page will clear the form.';
    }
  }
});


/***** Slider.js *****/
$.prototype.extend({
  advSlider : function(options) {
    settings = {
      //defaults
      id : $(this).attr('id'),
      value : $(this).attr('value'),
      min : $(this).attr('min'),
      max : $(this).attr('max'),
      step : $(this).attr('step'),
      orientation : "horizontal",
      onChange : function() {
      }
    }

    if( typeof (options) != 'undefined') {
      $.extend(settings, options)
    }

    var step = settings.step * 1.0;
    var min = settings.min * 1.0;
    var max = settings.max * 1.0;

    $(this).replaceWith("<div id='" + settings.id + "'><!-- --></div>");
    // var slider = createSlider(settings.id, settings.min, settings.max, settings.step, settings.orientation, settings.value);

    // slider.bind("slide", settings.onChange);
    // slider.bind("slidechange", settings.onChange);
  }
})

function createSlider(id, minval, maxval, stepval, orientation, value) {
  var slider = $('#' + id).slider({
    range : "min",
    min : minval * 1.0,
    max : maxval * 1.0,
    step : stepval * 1.0,
    value : value * 1.0,
    orientation : orientation
  });
  return slider;
};
