$(document).ready(function($){
  // Header
  $('.scheme-redirect-message__close').click( function() {
    $('.scheme-redirect-message').hide();
    event.preventDefault();
  });

  // Sliding tabs
  $('.sliding-tabs').each( function(index) {
    var numberOfTabs = (100 / $(this).find('.sliding-tabs__tab').length);
    $(this).find('.sliding-tabs__bar').css('width', numberOfTabs + '%');

    $(this).find('.sliding-tabs__tab').on('click focus', function() {
      finalLeftValue = (numberOfTabs * ($(this).index() - 1))
      moveSliderBar($(this), finalLeftValue);
      event.preventDefault();
    });
  });

  function moveSliderBar(parent, leftValue) {
    parent.siblings('.sliding-tabs__bar').css('left', leftValue + '%');
  };

  // Simple method for capturing modal focus.
  // Requires you to add 'first-modal-link' and 'last-modal-link' classes in

  $(".last-modal-link").keydown(function(e){
    if (e.which == 9 && (e.shiftKey == false) ) { //keycode for TAB and NOT HOLDING SHIFT
      e.preventDefault();
      $(".first-modal-link").focus();
    }
  });

  $(".first-modal-link").keydown(function(e){
    if (e.which == 9 && e.shiftKey ) { //keycode for Shift + TAB
      e.preventDefault();
      $(".last-modal-link").focus();
    }
  });
});
