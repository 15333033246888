(function() {
  // allow to export or this, so can just do root.whatever to attach to global namespace
  var check_url, gameOfLife, load_cal, restrict_member_type_radios, restrict_types, root,
    indexOf = [].indexOf;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  // Restrict  the member type drop down to the values for the selected scheme
  restrict_types = function() {
    var html, vals;
    vals = $('#scheme option[value=' + $('#scheme').val() + ']').attr('data-member-types');
    if (vals === void 0) {
      return false;
    }
    html = "<select name='member' id='member'>";
    $.each(vals.split(','), function(ind, type) {
      var capitalized, selected;
      selected = type === $('#member option[selected]').val() ? ' selected' : '';
      capitalized = type.charAt(0).toUpperCase() + type.slice(1);
      return html += "<option value='" + type.toLowerCase() + "'" + selected + ">" + capitalized + "</option>";
    });
    html += "</select>";
    if ($('#scheme').val() === 'sw_outlink' || $('#scheme').val() === 'card') {
      $('#member').hide();
      $('#member-type').hide();
      if (!($('#scheme').val() === 'card')) {
        return $('#sw-outlink-message').show();
      }
    } else {
      $('#member').show();
      $('#member-type').show();
      $('#sw-outlink-message').hide();
      return $('#member').replaceWith(html);
    }
  };

  root.restrict_types = restrict_types;

  restrict_member_type_radios = function() {
    var vals;
    vals = $('#scheme option[value=' + $('#scheme').val() + ']').attr('data-member-types');
    if (vals === void 0) {
      return false;
    }
    $('#member_type_select_active').hide();
    $('#member_type_select_deferred').hide();
    $('#member_type_select_pensioner').hide();
    $('.scheme-selector__member-type-container input').attr('disabled', true);
    return $.each(vals.split(','), function(ind, member_type) {
      $("#member_type_select_" + member_type.toLowerCase()).show();
      return $("#member_type_select_" + member_type.toLowerCase() + ' input').attr('disabled', false);
    });
  };

  root.restrict_member_type_radios = restrict_member_type_radios;

  // redirect to homepage if page is not for the member
  check_url = function() {
    var arr, current_url, exempUrl, i, in_err, len, newStructureStarters, starter;
    arr = [];
    exempUrl = ["/", "/index_legacy", "/search", "/image_library", "/feedbacks", "/email_collection_faqs", "/investments/investments_in_uncertain_times", "/library/interactive_form", "/library/pension_statement", "/library/calculator_lifestyle", "/library/hitting_the_target", "/library/pensions_talk_details", "/teaspoon/default", "/trustee_message", "/trustee_message_april_20", "/trustee_message_may_20", "/trustee_message_may_20_updated", "/trustee_message_july", "/trustee_message_august", "/trustee_message_august_20", "/trustee_message_september_20", "/trustee_message_october_20", "/trustee_message_november_20", "/trustee_message_october_23", "/users/sign_in", "/email_collection", "/future_benefits", "/trustee_funding_statement", "/trustee_funding_statement_24_february", "/trustee/mntd/trustee_role", "/trustee_statement_2023", "/mixed_investment_fund"];
    newStructureStarters = ["/yt"];
    $('.top_nav a').each(function() {
      return arr.push($(this).attr('href'));
    });
    $('.sub_nav a').each(function() {
      return arr.push($(this).attr('href'));
    });
    $('.contents-section a').each(function() {
      return arr.push($(this).attr('href'));
    });
    $('.footer a').each(function() {
      return arr.push($(this).attr('href'));
    });
    $('.top-link-extra a').each(function() {
      return arr.push($(this).attr('href'));
    });
    current_url = document.location.pathname;
    for (i = 0, len = newStructureStarters.length; i < len; i++) {
      starter = newStructureStarters[i];
      if (current_url.substring(0, starter.length) === starter) {
        return;
      }
    }
    if ((indexOf.call(arr, current_url) < 0) && (indexOf.call(exempUrl, current_url) < 0)) {
      in_err = $('.no-redirect').size() > 0;
      if (!in_err) {
        return document.location = '/';
      }
    }
  };

  // document.location = '/?page_not_available=true'

  // function force to scroll down to calculator in IE edge and IE11
  // But it breaks Chrome if the page loads prior to the modeller
  load_cal = function() {
    var yPos;
    if (!/webkit/.test(navigator.userAgent.toLowerCase())) {
      if (window.location.href.indexOf('#cal-anchor') > 0) {
        yPos = document.querySelector('#cal-anchor').getBoundingClientRect().top;
        if (yPos > 0) {
          return window.scrollTo(0, yPos);
        } else {
          return $("body").fadeIn(100);
        }
      }
    }
  };

  // function to convert text to title case
  String.prototype.toProperCase = function() {
    return this.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // game of life
  gameOfLife = function(block1, block2) {
    return $(block1).click(function() {
      if ($(block2).hasClass('showing')) {

      } else {
        $('.game_text_box').removeClass("showing");
        $(block2).addClass("showing");
      }
      return event.preventDefault();
    });
  };

  $(document).keydown(function(e) {
    if (e.keyCode === 27) {
      return $('.game_text_box').removeClass("showing");
    }
  });

  $(document).on('ready page:load', function() {
    var $activeMemorySpan, $memories, $table, activeMemory, button, buttons, interactiveContribution, labelEl, name, now, numLN, numNo, numb, searchEl, subNavNum, totalMemories, video, video2, video3, video4, wanted_location;
    wanted_location = window.location.pathname + window.location.hash;
    // piwik tracked event code
    $('.tracked-link, .investment-tracked-link').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Tab', $(this).attr('href')]);
      }
    });
    $('.link-tracked').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Link', $(this).attr('href')]);
      }
    });
    $('.tracked-accordion').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Accordion', $(this).attr('href')]);
      }
    });
    $('.internal-page-tracked-link').click(function(event) {
      return window._paq.push(['trackEvent', 'Internal page link', $(this).attr('href'), 'Link clicked at: ' + window.location.href]);
    });
    $('.duplicate-internal-page-tracked-link').click(function(event) {
      return window._paq.push(['trackEvent', 'Duplicate Internal page link', 'Position: ' + $(this).data('position'), 'Link: ' + $(this).attr('href')]);
    });
    // Homepage tracking
    $('.top-link-extra a').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Top link item clicked', $(this).attr('href')]);
      }
    });
    $('.lbg-button').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Main button clicked', $(this).attr('href')]);
      }
    });
    $('.tracked-tile').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Homepage secondary tile clicked', $(this).attr('href')]);
      }
    });
    $('.tracked-primary-tile').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Homepage primary tile clicked', $(this).attr('href')]);
      }
    });
    $('.sliding-tabs__content a').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Quick link visited', $(this).attr('href')]);
      }
    });
    $('.footer__links a').click(function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Footer item clicked', $(this).attr('href')]);
      }
    });
    // Video event tracking from footer
    $('video').click(function(event) {
      return window._paq.push(['trackEvent', 'Video', 'Play', $(this).attr('id')]);
    });
    // Hitting the Target Tracking
    $('.lt-welcome-page_start-btn').on('click', function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Hitting the Target', 'Started']);
      }
    });
    $('.ahc-lifetime-footer_links button').on('click', function(event) {
      if (window._paq) {
        return window._paq.push(['trackEvent', 'Hitting the Target', 'Disclaimer clicked']);
      }
    });
    // $('.force-reload-link').click (event) ->
    //   window.setTimeout(window.location.reload(), 5);
    $('#feedback_did_you_find_yes').change(function() {
      $('.feedback_did_you_find_what').toggleClass('hide', true);
      return $('.feedback_did_you_find_easy').toggleClass('hide', false);
    });
    $('#feedback_did_you_find_no').change(function() {
      $('.feedback_did_you_find_what').toggleClass('hide', false);
      return $('.feedback_did_you_find_easy').toggleClass('hide', true);
    });
    $('#change_select').click(function() {
      if ($('#scheme').val() === 'sw_outlink') {
        return window.location.href = 'https://www.scottishwidows.co.uk/save/yourretirementplan/';
      } else {
        $.get('/change_scheme/' + $('input[name=member]:checked').val() + '/' + $('#scheme').val(), function() {
          document.location = wanted_location;
          location.reload();
        });
      }
    });
    $('.news-feed .notification').click(function() {
      var long, note, short;
      note = $(this).find('.notification-content');
      short = note.data('short');
      long = note.data('content');
      if (note.text() === short) {
        if (long !== short) {
          return note.html(long);
        }
      } else {
        return note.html(short);
      }
    });
    $('.joining_select').click(function() {
      $.get('/change_scheme/active/yt', function() {
        document.location = '/joining';
      });
    });
    $('.yt_select').click(function() {
      $.get('/change_scheme/active/yt', function() {
        document.location = '/';
      });
    });
    check_url();
    load_cal();
    // restrict member type drop down
    restrict_types();
    restrict_member_type_radios();
    if ($("input:radio[name=member]:checked").length === 0) {
      $("input:radio[name=member]:visible:first").prop('checked', true);
    }
    $('#scheme').change(function() {
      restrict_types();
      restrict_member_type_radios();
      if ($("input:radio[name=member]:checked").prop('disabled')) {
        return $("input:radio[name=member]:visible:first").prop('checked', true);
      }
    });
    // cookies banner
    if (document.cookie.indexOf('_lloyds_website_accept') >= 0) {
      $('#cookies_notice').addClass("no_show");
    } else {
      $('#close_cookies').click(function() {
        //  if accept != '' and accept !=null
        document.cookie = "_lloyds_website_accept=";
        return $('#cookies_notice').addClass("no_show");
      });
    }
    // dropdown styling
    $('.drop-down-selection').click(function() {
      if ($('.dropdown_nav').hasClass('showing')) {
        $('.dropdown_nav').removeClass('showing');
      }
      $('.scheme-selector').toggleClass("showing");
      return event.preventDefault();
    });
    $('#close-select').click(function() {
      $('.scheme-selector').removeClass("showing");
      return event.preventDefault();
    });
    $('.scheme-selector__background').click(function() {
      $('.scheme-selector').removeClass("showing");
      return event.preventDefault();
    });
    $('#close-select-secondary').click(function() {
      $('.scheme-selector').removeClass("showing");
      return event.preventDefault();
    });
    // dropdown content in overlay landing page
    $('.expanding_link').click(function() {
      $('.expanding_content .toggler').toggleClass("showing");
      return event.preventDefault();
    });
    // Mobile navigation
    $('#mobile_nav').click(function() {
      $(this).toggleClass("active");
      if ($('.scheme-selector').hasClass('showing')) {
        $('.scheme-selector').removeClass('showing');
      }
      return $('.dropdown_nav').toggleClass("showing");
    });
    // set expiry for cookies
    now = new Date();
    //now.setTime
    now.getTime() + 1 * 3600 * 1000;
    now.setFullYear(now.getFullYear() + 1);
    // Overlay page when no scheme is selected
    // your tomorrow link
    $('.your_tomorrow_link').click(function() {
      document.cookie = 'scheme=yt; expires=' + now.toUTCString() + "; path=/";
      return document.cookie = 'member_type=active; expires=' + now.toUTCString() + "; path=/";
    });
    // location.reload()

    // pensioner section link
    $('.pensioner_link').click(function() {
      document.cookie = 'member_type=pensioner; expires=' + now.toUTCString() + "; path=/";
      return location.reload();
    });
    // dc modeller link
    $('.yt-tools-link').click(function() {
      document.cookie = 'scheme=yt; expires=' + now.toUTCString() + "; path=/";
      document.cookie = 'member_type=active; expires=' + now.toUTCString() + "; path=/";
      return location.reload();
    });
    // tab link
    $('.reload-link').click(function() {
      return location.reload();
    });
    //game_of_life
    $('.game_text_box .close_link').click(function() {
      $(this).parent().removeClass('showing');
      return event.preventDefault();
    });
    // piwik statistic for video
    // $('video').each ->
    //  this.addEventListener 'click', (->
    //    console.log this.id
    //  ), false

    //Videos autoplay:
    video = document.getElementById('threeOptionsVid');
    if (video) {
      video.addEventListener('click', (function() {
        video.play();
        $(this).css('opacity', '1');
      }), false);
    }
    video = document.getElementById('approachingVid');
    if (video) {
      video.addEventListener('click', (function() {
        video.play();
        $(this).css('opacity', '1');
      }), false);
    }
    video = document.getElementById('threeOptionsVid');
    if (video) {
      video.addEventListener('click', (function() {
        video.play();
        $(this).css('opacity', '1');
      }), false);
    }
    video2 = document.getElementById('annuityVid');
    if (video2) {
      video2.addEventListener('click', (function() {
        video2.play();
        $('.annuity-subheading').hide();
        $(this).css('opacity', '1');
      }), false);
    }
    video3 = document.getElementById('drawdownVid');
    if (video3) {
      video3.addEventListener('click', (function() {
        video3.play();
        $('.drawdown-subheading').hide();
        $(this).css('opacity', '1');
      }), false);
    }
    video4 = document.getElementById('cashVid');
    if (video4) {
      video4.addEventListener('click', (function() {
        video4.play();
        $('.cash-subheading').hide();
        $(this).css('opacity', '1');
      }), false);
    }
    //retirement planning options
    $('.retirement-option .close_link').click(function() {
      $(this).closest('.retirement-option').removeClass('showing');
      return event.preventDefault();
    });
    $('.op-link').click(function() {
      $('.retirement-option').removeClass('showing');
      $(this).parent().addClass('showing');
      return event.preventDefault();
    });
    gameOfLife('#block_3_yt', '#block_3_text');
    gameOfLife('#block_5_active', '#block_5_text');
    gameOfLife('#block_7', '#block_7_text');
    gameOfLife('#block_9_active', '#block_9_text');
    gameOfLife('#block_12', '#block_12_text');
    gameOfLife('#block_14', '#block_14_text');
    gameOfLife('#block_19_active', '#block_19_text');
    gameOfLife('#block_21_active', '#block_21_text');
    gameOfLife('#block_24', '#block_24_text');
    gameOfLife('#block_27_active', '#block_27_text');
    gameOfLife('#block_30_active', '#block_30_text');
    gameOfLife('#block_42', '#block_42_text');
    gameOfLife('#block_48', '#block_48_text');
    gameOfLife('#block_52', '#block_52_text');
    gameOfLife('#block_54', '#block_54_text');
    gameOfLife('#block_56', '#block_56_text');
    gameOfLife('#block_59', '#block_59_text');
    gameOfLife('#block_67', '#block_67_text');
    gameOfLife('#block_72', '#block_72_text');
    //hide and show content
    $('.expanding .expanding_title').click(function() {
      $(this).parent().toggleClass('showing');
      $(this).parent().find('.expanding_content').toggleClass('showing');
      return event.preventDefault();
    });
    // interactive contribution table
    interactiveContribution = function($table, button, classadd) {
      return $table.find(button).click(function(e) {
        $table.removeClass("one two three four five").addClass(classadd);
        return e.preventDefault();
      }).hover(function(e) {
        return $table.removeClass("one two three four five").addClass(classadd);
      });
    };
    $table = $(".interactive_contribution_table");
    buttons = {
      "#button_one": "one",
      "#button_two": "two",
      "#button_three": "three",
      "#button_four": "four",
      "#button_five": "five",
      "#button_1": "one",
      "#button_2": "two",
      "#button_3": "three",
      "#button_4": "four",
      "#button_5": "five"
    };
    for (button in buttons) {
      name = buttons[button];
      interactiveContribution($table, button, name);
    }
    $table = $(".interactive_contribution_tablev2");
    for (button in buttons) {
      name = buttons[button];
      interactiveContribution($table, button, name);
    }
    // latest news carousel number
    numLN = $('.slides-news-item').size();
    $('.rslides_news_tabs').addClass('items_' + numLN);
    // overview box number
    numb = $('.section_bottom .section-bk').size();
    if (numb === 0) {
      numb = $('.section_bottom .section_block').size();
    }
    $('.section_bottom').addClass('box_' + numb);
    // Print buttons
    $('.print-button').click(function(e) {
      return window.print();
    });
    $('.back-button').click(function(e) {
      return history.back(-1);
    });
    // your pension link
    $('#float-yp').hide();
    $(window).scroll(function() {
      var scroll, topHeight, totalHeight;
      topHeight = $('.header').height();
      totalHeight = $('#float-yp').height() + topHeight;
      //console.log(totalHeight)
      scroll = $(window).scrollTop();
      if (scroll > totalHeight) {
        return $('#float-yp').show();
      } else {
        return $('#float-yp').hide();
      }
    });
    // Sub navigation number
    subNavNum = $('.sub_nav a').size();
    $('.sub_nav').addClass('box_' + subNavNum - 1); //Accounts for homepage now being counted as an item
    
    // navigation number
    numNo = $('#footer .lv-1').size();
    $('#footer').addClass('nav_' + numNo);
    // YT retirement benefit page
    $('.toggleMoreText').click(function(e) {
      $(this).parent().children('div').toggle();
      $(this).parent().toggleClass('open');
      e.preventDefault();
    });
    $('.transcript-toggle').click(function(e) {
      e.preventDefault();
      $(this).closest('.video-box').find('.transcript-container').slideToggle();
    });
    $('.expand .question').click(function(e) {
      e.preventDefault();
      $(this).closest('.expand').find('.answer').slideToggle(function() {
        $(this).closest('.expand').find('.question').toggleClass('active');
      });
    });
    // pensioner section - memories
    activeMemory = 0;
    $activeMemorySpan = $('.activeMemorySpan');
    $memories = $('.memory');
    totalMemories = $memories.length;
    $('.totalMemories').text(totalMemories);
    $('.toggleMemory').on('click', function(event) {
      var $this;
      $this = $(this);
      activeMemory += $this.hasClass('previous') ? -1 : 1;
      if (activeMemory < 0) {
        activeMemory = totalMemories - 1;
      } else if (activeMemory === totalMemories) {
        activeMemory = 0;
      }
      $('.memory.active').fadeOut('slow', function() {
        $('.memory.active').removeClass('active');
        $memories.eq(activeMemory).fadeIn('slow').addClass('active');
      });
      $activeMemorySpan.text(activeMemory + 1);
      event.preventDefault();
    });
    // investment drop down select content
    // $('.group').hide()
    $('#yourjourneyextra').show();
    $('#yourdestinationincrease').show();
    $('.selectMe').change(function() {
      var $parent;
      $parent = $(this).parent();
      // done this for new multiline select system
      if ($parent.hasClass('multiline-select')) {
        $parent.find('.multiline-select-text').text($(this).find('option:selected').text());
        $parent = $parent.parent();
      }
      $parent.find('.group').hide();
      return $('#' + $(this).val()).show();
    });
    // search styling (http://callmenick.com/post/expanding-search-bar-using-css-transitions)
    searchEl = document.querySelector('#search_link #input');
    labelEl = document.querySelector('#search_link #label');
    // register clicks and toggle classes
    if (searchEl !== null) {
      labelEl.addEventListener('click', function() {
        if (classie.has(searchEl, 'focus')) {
          classie.remove(searchEl, 'focus');
          classie.remove(labelEl, 'active');
        } else {
          classie.add(searchEl, 'focus');
          classie.add(labelEl, 'active');
        }
      });
      // register clicks outisde search box, and toggle correct classes
      document.addEventListener('click', function(e) {
        var clickedID;
        clickedID = e.target.id;
        if (clickedID !== 'search-terms' && clickedID !== 'search-label') {
          if (classie.has(searchEl, 'focus')) {
            classie.remove(searchEl, 'focus');
            classie.remove(labelEl, 'active');
          }
        }
      });
    }
  });

}).call(this);
