$(document).ready(function () {
  var $additionalMessage = $('.page-feedback--content');
  var $scores = $('.page-feedback--score');
  var $outcome = $('.page-feedback--outcome');
  var $positive = $('.page-feedback--positive');
  var $negative = $('.page-feedback--negative');

  var feedbackSessionHash = $('.page-feedback-secret').text();
  $('.page-feedback-secret').hide();

  $outcome.hide();

  // Reset feedback container
  resetPageFeedback = function(){
    $('#page-feedback').removeClass('open');
    $('.page-feedback--heading').delay(250).removeClass('hide');
    $('.page-feedback--content-submitted').addClass('hide');
    $('.page-feedback--content-unsubmitted').removeClass('hide');
    $scores.removeClass('active');
    $additionalMessage.addClass('hide');
    $('.page-feedback--content-unsubmitted').find('textarea').val('');
  };

  // Open side panel containing feedback
  $('.page-feedback-side-header').click(function (event) {
    $(this).parent().toggleClass('open');
  });

  // Submit score and open comment box
  $scores.click(function (event) {
    var $this = $(this);
    $scores.removeClass('active');

    if (parseInt($this.attr('data-score')) > 0) {
      $positive.show();
      $negative.hide();
      $('[data-score="1"]').addClass('active');
    } else {
      $positive.hide();
      $negative.show();
      $this.addClass('active');
      $('[data-score="-1"]').addClass('active');
    }

    var $score = $('.page-feedback--score.active');

    if (!$score.length) {
      $outcome.show().text('Please submit a score');
      return false;
    }

    var feedback = {
      score: parseInt($score.attr('data-score')),
      url: window.location.href,
      session_hash: feedbackSessionHash
    };

    if (!$additionalMessage.hasClass('hide')) {
      feedback.message = $('.page-feedback--content-unsubmitted').find('textarea').val().replace(/\n/g," ").replace(',', '，');
    }

    $.post('/page_feedbacks', {
      feedback: feedback
    }).done(function (data) {
      if (data.id) {
        $additionalMessage.removeClass('hide');
      } else {
        $outcome.text(data[0]);
      }
    }).fail(function (data) {
      console.log('failed', data);
    });

    event.preventDefault();
  });


  // Submit comment and hide comment box
  $('.page-feedback--submit').click(function (event) {
    $outcome.hide();

    var $score = $('.page-feedback--score.active');

    if (!$score.length) {
      $outcome.show().text('Please submit a score');
      return false;
    }

    var feedback = {
      score: parseInt($score.attr('data-score')),
      url: window.location.href,
      session_hash: feedbackSessionHash
    };

    if (!$additionalMessage.hasClass('hide')) {
      feedback.message = $('.page-feedback--content-unsubmitted').find('textarea').val().replace(/\n/g," ").replace(',', '，');
    }

    $.post('/page_feedbacks', {
      feedback: feedback
    }).done(function (data) {
      if (data.id) {
        $('.page-feedback--content-submitted').removeClass('hide');
        $('.page-feedback--content-unsubmitted').addClass('hide');
        $('.page-feedback--heading').addClass('hide');
        setTimeout(resetPageFeedback, 1600);
      } else {
        $outcome.text(data[0]);
      }
    }).fail(function (data) {
      console.log('failed', data);
    });

    event.preventDefault();
  });
});
