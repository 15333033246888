$(document).ready(function () {

  // Tracking on the video
  $("video").on("pause", function (e) {

    // Detect time remaining, then treat anything within the last 7 seconds as a 'Finish'
    var timeRemaining = Math.floor(e.target.duration - e.target.currentTime)
    if (timeRemaining <= 7) {
      trackVideoEnded($(this).data('name'), e.target.currentTime);
    } else {
      if (window._paq) {
        window._paq.push(['trackEvent', 'Video', 'Paused', 'Video: ' + $(this).data('name'), e.target.currentTime])
      }
      gtag("event", "Video paused", {
        video_name: $(this).data('name'),
        video_timestamp: e.target.currentTime
      });
    }
  });

  $("video").on("play", function (e) {
    if (window._paq && e.target.currentTime == 0) {
      window._paq.push(['trackEvent', 'Video', 'Started', 'Video: ' + $(this).data('name')])
    } else if (window._paq) {
      window._paq.push(['trackEvent', 'Video', 'Resumed', 'Video: ' + $(this).data('name'), e.target.currentTime])
    }
    gtag("event", "Video play", {
      video_name: $(this).data('name'),
      video_timestamp: e.target.currentTime
    });
  });

  $("video").on("ended", function (e) {
    trackVideoEnded($(this).data('name'), e.target.currentTime);
  });

  $("video").on("seeked", function (e) {
    if (window._paq) {
      window._paq.push(['trackEvent', 'Video', 'Scrubbed', 'Video: ' + $(this).data('name'), e.target.currentTime])
    }
    gtag("event", "Video scrubbed", {
      video_name: $(this).data('name'),
      video_timestamp: e.target.currentTime
    });
  });

  trackVideoEnded = function (videoName, videoTimestamp) {
    if (window._paq) {
      window._paq.push(['trackEvent', 'Video', 'Finished', 'Video: ' + videoName])
    }
    gtag("event", "Video finished", {
      video_name: videoName,
      video_timestamp: videoTimestamp
    });
  };

  // ----------------

  // Video feedback panels

  var $videoFeedbackScoreLinks = $('.video-player__feedback-score');
  var $videoOutcome = $('.video-player__feedback-outcome');
  $videoOutcome.hide();
  $('.video-feedback-secret').hide();
  $('.video-player__feedback-thank-you').hide();

  resetVideoFeedback = function(videoFeedbackContainer){
    videoFeedbackContainer.find('textarea').val('');
    videoFeedbackContainer.find('.video-player__feedback-score').removeClass('active');
    videoFeedbackContainer.find('.video-player__feedback-comment').hide();
    videoFeedbackContainer.find('.video-player__feedback-thank-you').hide();
  };

  $('.video-player__feedback-cancel').click(function (event) {
    event.preventDefault();
    resetVideoFeedback($(this).parents('.video-player__feedback'));
  });


  // Video score click
  $videoFeedbackScoreLinks.click(function (event) {
    var $this = $(this);
    // Get parent to establish which video feedback we're in, to account for multiple
    var topLevelParent = $(this).parents('.video-player__feedback');
    var feedbackSessionHash = topLevelParent.find('.video-feedback-secret').text();

    // Remove classes from both positive and negative icons
    topLevelParent.find('.video-player__feedback-score').removeClass('active');

    // Decide which icon to give active class, whch label to show
    if (parseInt($this.attr('data-score')) > 0) {
      topLevelParent.find('.video-player__feedback-positive-label').show();
      topLevelParent.find('.video-player__feedback-negative-label').hide();
      topLevelParent.find('[data-score="1"]').addClass('active');
    } else {
      topLevelParent.find('.video-player__feedback-positive-label').hide();
      topLevelParent.find('.video-player__feedback-negative-label').show();
      $this.addClass('active');
      topLevelParent.find('[data-score="-1"]').addClass('active');
    }

    var $score = topLevelParent.find('.video-player__feedback-score.active');

    // Create a feedback object
    var feedback = {
      score: parseInt($score.attr('data-score')),
      url: window.location.href,
      video_name: topLevelParent.attr('data-name'),
      session_hash: feedbackSessionHash
    };


    // Get value of text box
    feedback.message = topLevelParent.find('textarea').val().replace(/\n/g," ").replace(',', '，');

    $.post('/video_feedbacks', {
      feedback: feedback
    }).done(function (data) {
      if (data.id) {
        // Show comment section of the feedback panel
      } else {
        topLevelParent.find('.video-player__feedback-outcome').text(data[0]);
      }
    }).fail(function (data) {
      console.log('failed', data);
    });

    topLevelParent.find('.video-player__feedback-comment').show();
    topLevelParent.find('.video-player__feedback-comment-box').focus();
    event.preventDefault();
  });

  $('.video-player__feedback-submit').click(function (event) {

    // Get parent to establish which video feedback we're in, to account for multiple
    var topLevelParent = $(this).parents('.video-player__feedback');
    var $score = topLevelParent.find('.video-player__feedback-score.active');
    var feedbackSessionHash = topLevelParent.find('.video-feedback-secret').text();

    // Backup for if no value was provided for thumbs score somehow
    if (!$score.length) {
      topLevelParent.find('.video-player__feedback-outcome').show().text('Please submit a score');
      return false;
    } else {
      topLevelParent.find('.video-player__feedback-outcome').hide();
    }

    // Create a feedback object
    var feedback = {
      score: parseInt($score.attr('data-score')),
      url: window.location.href,
      video_name: topLevelParent.attr('data-name'),
      session_hash: feedbackSessionHash
    };

    // Get value of text box
    feedback.message = topLevelParent.find('textarea').val().replace(/\n/g," ").replace(',', '，');

    // Send feedback
    $.post('/video_feedbacks', {
      feedback: feedback
    }).done(function (data) {
      topLevelParent.find('.video-player__feedback-rating').hide();
      topLevelParent.find('.video-player__feedback-comment').hide();
      topLevelParent.focus();
      topLevelParent.parent().find('.video-player__feedback-thank-you').text('Thank you for your feedback.');
      topLevelParent.parent().find('.video-player__feedback-thank-you').text('Thank you for your feedback.').show();
      if (data.id) {
      } else {
        topLevelParent.find('.video-player__feedback-outcome').text(data[0]);
      }
    }).fail(function (data) {
      console.log('failed', data);
    });

    event.preventDefault();
  });

  $('.video-player__transcript-toggle').click(function (event) {
    var topLevelParent = $(this).parents('.video-player');
    topLevelParent.find('.video-player__transcript').slideToggle();
    isTranscriptClosed = (this).text === 'Read the transcript';
    $(this).text(isTranscriptClosed ? 'Hide transcript' : 'Read the transcript');

    if (window._paq) {
      window._paq.push(['trackEvent', 'Video', 'Transcript toggle', 'Video: ' + $(this).data('name'), isTranscriptClosed ? 'Opened' : 'Closed'])
    }
    gtag("event", "Transcript toggle", {
      video_name: $(this).data('name'),
      transcript_toggle_reason: isTranscriptClosed ? 'Opening' : 'Closing'
    });
  });
});
